import { useIsAuthenticated } from '@azure/msal-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QRCode from "react-qr-code";
import { Modal, AppBar, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import LinkIcon from '@mui/icons-material/Link';
import { SignOutButton } from './SignOutButton';
import { globalMediaPipeObject } from '../utils/global-objects';
import Swal from 'sweetalert2';
import copy from 'copy-to-clipboard';
import { style,secondaryColor, AppBarStyle } from '../styles/styles';
import * as CryptoJS from "crypto-js";

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar
}))
export const Navbar = () => {

    var privateKey = `${process.env.REACT_APP_SECRET_KEY_AES_CRYPTO}`;
    const isAuthenticated = useIsAuthenticated();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [numberNull, setnumberNull] = useState(false);
    const [Url, setUrln] = useState('');


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function CopyUrl () {
        
        copy(Url);
        setIsDrawerOpen(false);
        
        Swal.fire({
          icon: 'success',
          title: 'Copiado',
          color: '#002850',
          confirmButtonColor: '#002850',
          confirmButtonText: "ok",
        })
    }






    useEffect(() => {

        if (globalMediaPipeObject.clientNumber === "") {
            setnumberNull(false)
        } else {
            setnumberNull(true)
        }
        setUrln(`https://api.whatsapp.com/send/?phone=57${globalMediaPipeObject.clientNumber}&text=${globalMediaPipeObject.AttendeeName.replace(/_/g, " ").toUpperCase()},+bienvenido+a+nuestro+servicio+de+videollamada.+Tu+Consultor+Pibank+asignado+es+${globalMediaPipeObject.AttendeeNameAgent.replace(/_/g, " ").toUpperCase()},+conéctate+a+través+del+siguiente+enlace:+${window.location.href}%23/session/${CryptoJS.AES.encrypt(`id=${globalMediaPipeObject.MeetingTitle}/nameClient=${globalMediaPipeObject.AttendeeName}/nameAgent=${globalMediaPipeObject.AttendeeNameAgent}`,privateKey).toString()}`)
    })




    return (
        <Box>
            <AppBar style={AppBarStyle}>
                <Toolbar>
                    <img
                        src="https://www.pibank.es/wp-content/uploads/2017/12/logo-Pibank-inverso.svg"
                        width="120px"
                    ></img>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                    </Typography>
                    {isAuthenticated ?
                        <div>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => setIsDrawerOpen(true)}
                            >
                                <MenuIcon className="menu-icon"/>
                            </IconButton>
                            <Drawer anchor={"right"} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                                <List >
                                    {numberNull ?
                                    <>
                                        <ListItem button onClick={handleOpen}>
                                            <ListItemIcon><QrCodeScannerIcon className="menu-icon" /></ListItemIcon>
                                            <ListItemText  secondaryTypographyProps={{ style: secondaryColor }}
                                                secondary="Qr whatsapp..." />            
                                        </ListItem>

                                        <ListItem button onClick={CopyUrl}>
                                            <ListItemIcon><LinkIcon className="menu-icon" /></ListItemIcon>
                                            <ListItemText  secondaryTypographyProps={{ style: secondaryColor }}
                                                secondary="whatsapp web..." />            
                                        </ListItem>
                                        
                                        </> : <></>}

                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <ListItemIcon><ListItemText secondaryTypographyProps={{ style: secondaryColor }} secondary="Escanea el codigo QR:" /></ListItemIcon>
                                            <QRCode value={Url} />      
                                        </Box>

                                       
                                    </Modal>                                

                                    {numberNull ?
                                    <div></div>
                                        :  <ListItem button>

                                        <SignOutButton />
                                    </ListItem>}

                                </List>
                            </Drawer>
                        </div>
                        : <div></div>}
                </Toolbar>
            </AppBar>
        </Box>
    )
}
