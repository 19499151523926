
import Swal from 'sweetalert2';
import copy from 'copy-to-clipboard';
import { globalMediaPipeObject } from '../utils/global-objects';
import * as CryptoJS from "crypto-js";


export function useURlCopy() {




  // Copied from https://github.com/travist/jsencrypt
  var privateKey = `${process.env.REACT_APP_SECRET_KEY_AES_CRYPTO}`;

  // Assign our encryptor to utilize the public key.
  

  Swal.fire({
    icon: 'success',
    title: 'Copie esta url para contactar con su clientes',
    text: window.location.href + '#/session/' + `${CryptoJS.AES.encrypt(`id=${globalMediaPipeObject.MeetingTitle}/nameClient=${globalMediaPipeObject.AttendeeName}/nameAgent=${globalMediaPipeObject.AttendeeNameAgent}`,privateKey).toString()}`,
    color: '#002850',
    confirmButtonColor: '#002850',
    confirmButtonText: "Copiar",
  }).then((result) => {
    if (result.value)

      copy(window.location.href + '#/session/' + `${CryptoJS.AES.encrypt(`id=${globalMediaPipeObject.MeetingTitle}/nameClient=${globalMediaPipeObject.AttendeeName}/nameAgent=${globalMediaPipeObject.AttendeeNameAgent}`,privateKey).toString()}`);
    Swal.fire({
      icon: 'success',
      title: 'Copiado',
      color: '#002850',
      confirmButtonColor: '#002850',
      confirmButtonText: "ok",
    })
  });


}

