import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Navbar } from './components/Navbar';
import { SessionMeet } from './components/SessionMeet';
import { Home } from './components/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './styles/globals.css'
import '../src/assets/fonts/bpc-fonts/bpc-Radomir.woff'


Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<SessionMeet />} />
      <Route path="/home" element={<Home />} />
      <Route path="/navbar" element={<Navbar />} />
        <Route path="#/session/:session" element={<SessionMeet />} />
    </Routes>
  </BrowserRouter>
);