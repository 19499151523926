import { CreateTableCommand, DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";
import { getParamDynamoDBMediaCapturePipeline, getParamDynamoDBMediaConcatenationPipeline } from "./params_dynamodb";

const AccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    SecretKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!;
const creds = { accessKeyId: AccessKeyId, secretAccessKey: SecretKey };

const marshallOptions = {
    // Whether to automatically convert empty strings, blobs, and sets to `null`.
    convertEmptyValues: false, // false, by default.
    // Whether to remove undefined values while marshalling.
    removeUndefinedValues: true, // false, by default.
    // Whether to convert typeof object to map attribute.
    convertClassInstanceToMap: true, // false, by default.
};

const unmarshallOptions = {
    // Whether to return numbers as a string instead of converting them to native JavaScript numbers.
    wrapNumbers: false, // false, by default.
};

const translateConfig = { marshallOptions, unmarshallOptions };

//using ES6 syntax.
const dynamoDBClient = new DynamoDBClient({ credentials: creds, region: process.env.REACT_APP_REGION });
// Create the DynamoDB document client.
const ddbDocClient = DynamoDBDocumentClient.from(dynamoDBClient, translateConfig);
export { dynamoDBClient };
export { ddbDocClient };


export function getParamsTableCapturePipeline() {
    return new CreateTableCommand(getParamDynamoDBMediaCapturePipeline());
}
export function getParamsTableConcatenationPipeline() {
    return new CreateTableCommand(getParamDynamoDBMediaConcatenationPipeline());
}
