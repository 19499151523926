export const dateMeeting = dateMeetingISO8601();

export function getParamCapturePipe(MeetingID: string) {
  const params = {
    SourceType: "ChimeSdkMeeting",
    SourceArn: `arn:aws:chime::${process.env.REACT_APP_ACCOUNT_ID}:meeting:${MeetingID}`,
    SinkType: "S3Bucket",
    SinkArn: `arn:aws:s3:::amplify-video-store-meeting-${process.env.REACT_APP_ENVIRONMENT}/recordings/${dateMeeting}`,
    ChimeSdkMeetingConfiguration: {
      ArtifactsConfiguration: {
        Audio: {
          MuxType: "AudioWithCompositedVideo",
        },
        Video: {
          State: "Disabled",
          MuxType: "VideoOnly",
        },
        Content: {
          State: "Disabled",
          MuxType: "ContentOnly",
        },
        CompositedVideo: {
          Layout: "GridView",
          Resolution: "FHD",
          GridViewConfiguration: {
            ContentShareLayout: "Horizontal",
          },
        },
      },
    },
  };

  return params;
}

export function getParamConcatenationPipe(MediaPipelineArn: string) {
  const paramsConcatenation = {
    Sources: [
      {
        Type: "MediaCapturePipeline",
        MediaCapturePipelineSourceConfiguration: {
          MediaPipelineArn: MediaPipelineArn,
          ChimeSdkMeetingConfiguration: {
            ArtifactsConfiguration: {
              Audio: {
                State: "Enabled",
              },
              Video: {
                State: "Enabled",
              },
              Content: {
                State: "Enabled",
              },
              DataChannel: {
                State: "Enabled",
              },
              TranscriptionMessages: {
                State: "Enabled",
              },
              MeetingEvents: {
                State: "Enabled",
              },
              CompositedVideo: {
                State: "Enabled",
              },
            },
          },
        },
      },
    ],
    Sinks: [
      {
        Type: "S3Bucket",
        S3BucketSinkConfiguration: {
          Destination: `arn:aws:s3:::amplify-video-store-meeting-concatenation-${process.env.REACT_APP_ENVIRONMENT}/concatenation-recordings/${dateMeeting}`,
        },
      },
    ],
  };

  return paramsConcatenation;
}

export function dateMeetingISO8601() {
  const localDate = new Date(); // Crea un objeto Date con la fecha y hora ac
  const year = localDate.getFullYear();
  const month = localDate.getMonth() + 1; // Añade 1 para obtener el mes en el rango de 1 a 12
  const day = localDate.getDate();
  const hours = localDate.getHours();
  const minutes = localDate.getMinutes();
  const seconds = localDate.getSeconds();
  const monthPadded = month.toString().padStart(2, "0");
  const dayPadded = day.toString().padStart(2, "0");
  const hoursPadded = hours.toString().padStart(2, "0");
  const minutesPadded = minutes.toString().padStart(2, "0");
  const secondsPadded = seconds.toString().padStart(2, "0");
  const dateISO = `${year}-${monthPadded}-${dayPadded}-${hoursPadded}:${minutesPadded}:${secondsPadded}`;
  return dateISO;
}
