

export function getParamDynamoDBMediaCapturePipeline(){
    const  paramsCaptureDynamoDB = {
        AttributeDefinitions: [
          {
            AttributeName: 'mediaCapturePipeline_Id',
            AttributeType: 'S'
          },
          {
            AttributeName: 'mediaCapturePipeline',
            AttributeType: 'S'
          }
        ],
        KeySchema: [
          {
            AttributeName: 'mediaCapturePipeline_Id',
            KeyType: 'HASH'
          }
        ],
        ProvisionedThroughput: {
          ReadCapacityUnits: 1,
          WriteCapacityUnits: 1
        },
        TableName: `CapturePipelineTable-${process.env.REACT_APP_ENVIRONMENT}`,
        StreamSpecification: {
          StreamEnabled: false
        }
    };
    return paramsCaptureDynamoDB;
}

export function getParamDynamoDBMediaConcatenationPipeline(){
    const  paramsConcatenationDynamoDB = {
        AttributeDefinitions: [
          {
            AttributeName: 'mediaConcatenationPipeline_Id',
            AttributeType: 'S'
          },
          {
            AttributeName: 'mediaConcatenationPipeline',
            AttributeType: 'S'
          }
        ],
        KeySchema: [
          {
            AttributeName: 'mediaConcatenationPipeline_Id',
            KeyType: 'HASH'
          },
          {
            AttributeName: 'mediaConcatenationPipeline',
            KeyType: 'RANGE'
          }
        ],
        ProvisionedThroughput: {
          ReadCapacityUnits: 1,
          WriteCapacityUnits: 1
        },
        TableName: `ConcatenationPipelineTable-${process.env.REACT_APP_ENVIRONMENT}`,
        StreamSpecification: {
          StreamEnabled: false
        }
    };
    return paramsConcatenationDynamoDB;
}

export function getParamDynamoDBToPutItemMediaCapturePipeline(nameTable: any, primaryKey: any, data?:any){
  const paramsPutItem = {
    TableName: nameTable,
    Item: {
      mediaCapturePipeline_Id: primaryKey,
      data: data,
    },
  };
  return paramsPutItem;
}

export function getParamDynamoDBToPutItemMediaConcatenationPipeline(nameTable: any, primaryKey: any, data?:any){
  const paramsPutItem = {
    TableName: nameTable,
    Item: {
      mediaConcatenationPipeline_Id: primaryKey,
      data: data,
    },
  };
  return paramsPutItem;
}