import { ChimeSDKMediaPipelinesClient } from "@aws-sdk/client-chime-sdk-media-pipelines";
import { ChimeClient } from "@aws-sdk/client-chime";

const AccessKeyId =  process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    SecretKey =  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!;
    const creds = { accessKeyId: AccessKeyId, secretAccessKey: SecretKey };


export function getClientMediaPipe(){
    const client = new ChimeSDKMediaPipelinesClient({ credentials: creds, region: process.env.REACT_APP_REGION });
return client;
}

export function getClientChime(){
    const clientChime = new ChimeClient({ credentials: creds, region: process.env.REACT_APP_REGION });
return clientChime;
}
