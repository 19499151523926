import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";

import { Button, Card, CardContent, Container } from "@material-ui/core";

import {
  Flex,
  FormField,
  Input,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import {
  addAttendeeToDB,
  addMeetingToDB,
  createMeeting,
  getAttendeeFromDB,
  getMeetingFromDB,
  joinMeeting,
} from "../utils/api";
import Swal from "sweetalert2";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  CreateMediaConcatenationPipelineCommand,
  CreateMediaCapturePipelineCommand,
} from "@aws-sdk/client-chime-sdk-media-pipelines";
import {
  dateMeeting,
  dateMeetingISO8601,
  getParamCapturePipe,
  getParamConcatenationPipe,
} from "../params_aws/params_media_pipes";
import { getClientMediaPipe } from "../params_aws/params_create_client_chime_pipe";
import "../styles/globals.css";
import { BounceLoader } from "react-spinners";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { ddbDocClient } from "../params_aws/params_get_resources_aws";
import {
  getParamDynamoDBToPutItemMediaCapturePipeline,
  getParamDynamoDBToPutItemMediaConcatenationPipeline,
} from "../params_aws/params_dynamodb";
import { PutCommand } from "@aws-sdk/lib-dynamodb";
import { globalMediaPipeObject } from "../utils/global-objects";
import { Home } from "./Home";
import { loginRequest } from "../azure-auth-config";
import { callMsGraph } from "../graph";
import { ButtonMeetingStyle, staticStyles } from "../styles/styles";
import * as CryptoJS from "crypto-js";
const MeetingForm = () => {
  

 

  var privateKey = `${process.env.REACT_APP_SECRET_KEY_AES_CRYPTO}`;
 


  const meetingManager = useMeetingManager();
  const [meetingTitle, setMeetingTitle] = useState("");
  const [attendeeName, setName] = useState("");

  const [clientNumber, setclientNumber] = useState("");

  const [show, setShow] = useState(true);
  const [showURL, setShowURL] = useState(false);
  const [loading, setLoading] = useState(false);
  let { nameAgent } = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [errorMeetingTitle, setErrorMeetingTitle] = useState(false);
  const [messageErrorMeetingTitle, setMessageErrorMeetingTitle] =
    useState("Campo vacio");
  const [errorAttendeeNameAgent, setErrorAttendeeNameAgent] = useState(false);
  const [messageerrorAttendeeNameAgent, setMessageerrorAttendeeNameAgent] =
    useState("Campo vacio");
  const [errorAttendeeName, setErrorAttendeeName] = useState(false);
  const [messageErrorAttendeeName, setMessageErrorAttendeeName] =
    useState("Campo vacio");
  const [errorClientNumber, setErrorClientNumber] = useState(false);
  const [messageErrorClientNumber, setMessageErrorClientNumber] =
    useState("Campo vacio");
  const [Authenticated, setAuthenticated] = useState(isAuthenticated);
  const [validationUrlName, setValidationUrlName] = useState("");
  const [validationUrlId, setValidationUrlId] = useState("");
  const [validationUrlNameAgent, setValidationUrlNameAgent] = useState("");
  const [attendeeNameAgent, setNameAgent] = useState("");
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }

  const { hash } = useLocation();

  let lastUrl = useMemo(() => hash, []);

  function SplitUrl() {
    debugger;

    var encrypted: any = hash.split("#/session/");
    var uri: any = CryptoJS.AES.decrypt(encrypted[1], privateKey);
    uri = uri.toString(CryptoJS.enc.Utf8);

    let arr = uri?.split("/") || "";
    let id = arr[0]?.split("=") || "";
    let nameClient = arr[1]?.split("=") || "";
    let nameAgent = arr[2]?.split("=") || "";
    let id2: any = id[1]?.toString() || "";
    let nameClient2: any =
      nameClient?.length > 0
        ? nameClient[1]?.toString() || ""
        : nameClient[0]?.toString() || "";
    let nameAgent2: any = nameAgent[1]?.toString() || "";
    setValidationUrlId(id2);
    setValidationUrlName(nameClient2);
    setValidationUrlNameAgent(nameAgent2);
    //setNameAgent(atob(nameAgent2));
    setAuthenticated(true);
  }

  useEffect(() => {
    if (isAuthenticated) {
      setNameAgent(
        (accounts[0]?.name as string).replaceAll(/( )|(. )/g, "_") || ""
      );
      setMeetingTitle(dateMeetingISO8601());
    }

    if (hash !== "") {
      SplitUrl();
    }

    window.scrollTo(0, 0);
    if (
      validationUrlId === "" &&
      validationUrlName === "" &&
      validationUrlNameAgent == ""
    ) {
      setShowURL(true);
    } else {
      setMeetingTitle(validationUrlId);
      setName(decodeURIComponent(validationUrlName));
      setNameAgent(decodeURIComponent(validationUrlNameAgent));
      //setNameAgent(nameAgent!);
      setShowURL(true);
    }

    if (lastUrl !== "") {
      window.onhashchange = function () {
        const dominio = window.location.origin;
        window.location.href = `${dominio}/${lastUrl}`;
      };
    }
  });

  function getAttendeeCallback() {
    return async (chimeAttendeeId: string, externalUserId?: string) => {
      const attendeeInfo: any = await getAttendeeFromDB(chimeAttendeeId);
      const attendeeData = attendeeInfo.data.getAttendee;
      return {
        //name: attendeeData ? attendeeData.name : "name"
        name: attendeeData.name,
      };
    };
  }

  async function putItemIntoTableMediaPipeline(
    nameTable: any,
    primaryKey: any,
    data?: any
  ) {
    try {
      const dataDocClient = await ddbDocClient.send(
        new PutCommand(
          getParamDynamoDBToPutItemMediaCapturePipeline(
            nameTable,
            primaryKey,
            data
          )
        )
      );
      console.log(
        `${process.env.REACT_APP_LOG__DYNAMODB_SUCCESS}`,
        dataDocClient
      );
    } catch (error: any) {
      console.log(`${process.env.REACT_APP_LOG__DYNAMODB_ERROR}`, error);
    }
  }

  async function createMediaPipeline(MeetingID: any) {
    const client = getClientMediaPipe();
    const command = new CreateMediaCapturePipelineCommand(
      getParamCapturePipe(`${MeetingID}`)
    );
    try {
      const data = await client.send(command);
      await ddbDocClient.send(
        new PutCommand(
          getParamDynamoDBToPutItemMediaCapturePipeline(
            `CapturePipelineTable-${process.env.REACT_APP_ENVIRONMENT}`,
            data.MediaCapturePipeline?.MediaPipelineId,
            data
          )
        )
      );

      localStorage.setItem("dataCapture", JSON.stringify(data));

      console.log(`${process.env.REACT_APP_LOG_DATA_CAPTURE}`, data);
      if (data) {
        const commandConcatenation =
          new CreateMediaConcatenationPipelineCommand(
            getParamConcatenationPipe(
              `${data.MediaCapturePipeline?.MediaPipelineArn}`
            )
          );
        const dataConcatenation = await client.send(commandConcatenation);
        await ddbDocClient.send(
          new PutCommand(
            getParamDynamoDBToPutItemMediaConcatenationPipeline(
              `ConcatenationPipelineTable-${process.env.REACT_APP_ENVIRONMENT}`,
              dataConcatenation.MediaConcatenationPipeline?.MediaPipelineId,
              data
            )
          )
        );

        localStorage.setItem(
          "dataConcatenation",
          JSON.stringify(dataConcatenation)
        );
        console.log(
          `${process.env.REACT_APP_LOG_DATA_CONCATENATION}`,
          dataConcatenation
        );

        globalMediaPipeObject.MediaPipelineId =
          data.MediaCapturePipeline?.MediaPipelineId!;
        globalMediaPipeObject.MeetingID = MeetingID;
        Object.freeze(globalMediaPipeObject);
        localStorage.setItem(
          "MediaPipelineId",
          data.MediaCapturePipeline?.MediaPipelineId!
        );
        localStorage.setItem("MeetingID", MeetingID);
      }
    } catch (error: any) {
      console.log(`${process.env.REACT_APP_LOG_ERROR}`, error);
    }
  }

  const clickedJoinMeeting = async (event: FormEvent) => {

    if (meetingTitle.length === 0) {
      setErrorMeetingTitle(true);
    } else if (meetingTitle.length > 0 && meetingTitle.length < 4) {
      setErrorMeetingTitle(true);
      setMessageErrorMeetingTitle("Mínimo 4 caracteres");
    } else {
      setErrorMeetingTitle(false);
    }

    if (attendeeNameAgent.length === 0) {
      setErrorAttendeeNameAgent(true);
    } else if (attendeeNameAgent.length > 0 && attendeeNameAgent.length < 4) {
      setErrorAttendeeNameAgent(true);
      setMessageerrorAttendeeNameAgent("Mínimo 4 caracteres");
    } else {
      setErrorAttendeeNameAgent(false);
    }

    if (attendeeName.length === 0) {
      setErrorAttendeeName(true);
    } else if (attendeeName.length > 0 && attendeeName.length < 4) {
      setErrorAttendeeName(true);
      setMessageErrorAttendeeName("Mínimo 4 caracteres");
    } else {
      setErrorAttendeeName(false);
    }

    if (clientNumber.length === 0) {
      setErrorClientNumber(true);
    } else {
      setErrorClientNumber(false);
    }


    if(clientNumber == '' || attendeeName == ''){
      if (clientNumber.length === 0) {
        setErrorClientNumber(true);
      } else {
        setErrorClientNumber(false);
      }
      
      if (attendeeName.length === 0) {
        setErrorAttendeeName(true);
      } else if (attendeeName.length > 0 && attendeeName.length < 4) {
        setErrorAttendeeName(true);
        setMessageErrorAttendeeName("Mínimo 4 caracteres");
      } else {
        setErrorAttendeeName(false);
      }

    }else{
    if (
      meetingTitle.length >= 4 &&
      attendeeNameAgent.length >= 4 &&
      attendeeName.length >= 4 &&
      clientNumber !== ''
    ) {
      setShow(!show);
      setLoading(true);
    }

    event.preventDefault();
    meetingManager.getAttendee = getAttendeeCallback();
    const title = meetingTitle.trim().toLocaleLowerCase();
    let name = attendeeName;
    let number = clientNumber;

    if (showURL) {
      name = attendeeNameAgent;
    }
    globalMediaPipeObject.AttendeeName = attendeeName
      .trim()
      .replace(/\s+/g, "_");
    globalMediaPipeObject.MeetingTitle = meetingTitle
      .trim()
      .toLocaleLowerCase();
    globalMediaPipeObject.AttendeeNameAgent = attendeeNameAgent;
    globalMediaPipeObject.clientNumber = number;

    const meetingResponse: any = await getMeetingFromDB(title);
    const meetingJson = meetingResponse.data.getMeeting;

    try {
      if (meetingJson) {
        const meetingData = JSON.parse(meetingJson.data);
        const joinInfo = await joinMeeting(meetingData.MeetingId, name);
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
          meetingData,
          joinInfo.Attendee
        );
        await meetingManager.join(meetingSessionConfiguration);
        setLoading(false);
      } else {
        Swal.fire({
          icon: "success",
          title: "sesion creada!",
          showConfirmButton: false,
          timer: 1500,
          color: "#002850",
        });

        window.scrollTo({ top: 80, left: 0, behavior: "smooth" });
        const joinInfo = await createMeeting(title, name, "us-east-1");
        await addMeetingToDB(
          title,
          joinInfo.Meeting.MeetingId,
          JSON.stringify(joinInfo.Meeting)
        );
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
          joinInfo.Meeting,
          joinInfo.Attendee
        );
        await meetingManager.join(meetingSessionConfiguration);

        createMediaPipeline(joinInfo.Meeting.MeetingId);

        setLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        color: "#002850",
      });
      setLoading(false);
      setTimeout(() => {
        window.location.replace("");
      }, 5000);
    }
    // At this point you can let users setup their devices, or start the session immediately
    await meetingManager.start();
  }
  };
  return (
    <div>
      {Authenticated ? (
        <div>
          <Container>
            {loading ? (
              <div className="loader">
                <BounceLoader size={70} color={"#FFDC00"} loading={loading} />
              </div>
            ) : (
              <div>
                {show && (
                  <Card className="position-card">
                    <CardContent>
                      <Flex layout="fill-space-centered">
                        {showURL ? (
                          <h2 className="welcome-text">
                            Agente: Bienvenido a la Videollamada de Pibank!!{" "}
                          </h2>
                        ) : (
                          <h2 className="welcome-text">
                            Cliente: Bienvenido a la Videollamada de Pibank!!
                          </h2>
                        )}
                      </Flex>
                      <form>
                        <Flex
                          container
                          layout="equal-columns"
                          style={{ marginTop: "1.5rem" }}
                        >
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;
                          <div>
                            <h5 className="flex">Meeting &nbsp; Id: </h5>
                            <FormField
                              layout="stack"
                              field={Input}
                              label=""
                              value={meetingTitle}
                              fieldProps={{
                                name: "Meeting Id",
                                placeholder: "Ingrese el ID del Meeting ",
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                if(e.target.value !== ''){
                                  setErrorMeetingTitle(false)
                                }else{
                                  setErrorMeetingTitle(true)
                                }
                                setMeetingTitle(e.target.value);
                              }}
                              css={staticStyles}
                              errorText={messageErrorMeetingTitle}
                              error={errorMeetingTitle}
                            />
                          </div>
                          <div>
                            <h5 className="flex">Nombre Agente: </h5>
                            <FormField
                              field={Input}
                              label=""
                              value={attendeeNameAgent}
                              fieldProps={{
                                name: "Name",
                                placeholder: "Ingrese el nombre de agente",
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                if(e.target.value !== ''){
                                  setErrorAttendeeNameAgent(false)
                                }else{
                                  setErrorAttendeeNameAgent(true)
                                }
                                setNameAgent(e.target.value);
                              }}
                              css={staticStyles}
                              errorText={messageerrorAttendeeNameAgent}
                              error={errorAttendeeNameAgent}
                            />
                          </div>
                          <div>
                            <h5 className="flex">Nombre Cliente: </h5>
                            <FormField
                              field={Input}
                              label=""
                              value={attendeeName}
                              fieldProps={{
                                name: "Name",
                                placeholder: "Ingrese el nombre de cliente",
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                if(e.target.value !== ''){
                                  setErrorAttendeeName(false)
                                }else{
                                  setErrorAttendeeName(true)
                                }
                                setName(e.target.value);
                              }}
                              css={staticStyles}
                              errorText={messageErrorAttendeeName}
                              error={errorAttendeeName}
                            />
                          </div>
                          {showURL ? (
                            <div>
                              <h5 className="flex">Numero cliente: </h5>
                              <FormField
                                layout="stack"
                                field={Input}
                                label=""
                                value={clientNumber}
                                fieldProps={{
                                  name: "Meeting Id",
                                  placeholder: "Ingrese el número de cliente",
                                }}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  if(e.target.value !== ''){
                                    setErrorClientNumber(false)
                                  }else{
                                    setErrorClientNumber(true)
                                  }
                                  setclientNumber(e.target.value);
                                }}
                                css={staticStyles}
                                errorText={messageErrorClientNumber}
                                error={errorClientNumber}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Flex>
                        <Flex
                          container
                          layout="fill-space-centered"
                          className="flex-button"
                        >
                          {showURL ? (
                            <Link
                              className="decoration-link"
                              to={meetingTitle !== '' && attendeeNameAgent !== '' && attendeeName !== '' &&  clientNumber !== '' ?  `#/session/${CryptoJS.AES.encrypt(
                                `id=${meetingTitle}/nameClient=${attendeeName}/nameAgent=${attendeeNameAgent}`,
                                privateKey
                              ).toString()}/`:''}
                            >
                              {" "}
                              <Button
                                onClick={clickedJoinMeeting}
                                style={ButtonMeetingStyle}
                                variant="contained"
                              >
                                Crear videollamada
                              </Button>
                            </Link>
                          ) : (
                            <Link
                              className="decoration-link"
                              to={meetingTitle !== '' && attendeeNameAgent !== '' && attendeeName !== '' &&  clientNumber !== '' ?  `#/session/${CryptoJS.AES.encrypt(
                                `id=${meetingTitle}/nameClient=${attendeeName}/nameAgent=${attendeeNameAgent}`,
                                privateKey
                              ).toString()}/`:''}
                            >
                              {" "}
                              <Button
                                onClick={clickedJoinMeeting}
                                style={ButtonMeetingStyle}
                                variant="contained"
                              >
                                Unirse a videollamada
                              </Button>
                            </Link>
                          )}
                        </Flex>
                      </form>
                    </CardContent>
                  </Card>
                )}
              </div>
            )}
          </Container>
        </div>
      ) : (
        <div>
          <Home></Home>
        </div>
      )}
    </div>
  );
};

export default MeetingForm;
