


export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'

};

export const secondaryColor = {
    color: '#002850',
    fontFamily: ' Radomir',
    fontSize: 20
}



export const SignInButtonStyle = {
    fontFamily: 'Radomir',
    background: '#FFF ',
    color: '#002850',
    lineHeight: '18px',
    padding: '10px 120px',
    borderRadius: '25px',
    fontWeight: 'bold'
}



export const SignOutButtonStyle = {
    fontFamily: ' Radomir',
    background: '#FFF ',
    color: '#002850',
    lineHeight: '18px',
    padding: '10px 80px',
    borderRadius: '25px',
    fontWeight: 'bold'
}


export const ButtonMeetingStyle ={
    background: '#0F265C', 
    color: 'white', 
    fontFamily: 'Radomir' 
} 


export const ControlBarStyle={
     backgroundColor: '#FFDC00' 
}


export const AppBarStyle={
    background: '#FFFFFF'
}


export const staticStyles = `width: 90%;`;