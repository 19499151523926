import { Card, CardContent, Container, Grid, } from '@material-ui/core'
import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';

import { SignInButton } from './SignInButton'
import { SignOutButton } from './SignOutButton'

export const Home = () => {
    useEffect(() => {
        window.scrollTo({ top: 80, left: 0, behavior: 'smooth' });
    })
    const isAuthenticated = useIsAuthenticated();
    return (
        <Container className="container-grid">
            <Grid container className="grid-banner" >
                <Card >
                    <CardContent>
                        <h2 className="welcome-card-tittle">Bienvenido al meeting de Pibank!! </h2>
                        <Grid container justifyContent="center">
                            <img className='logo-paid-account' alt="logo-paid-account" />
                        </Grid>
                    </CardContent>
                </Card>
                <div className="info-card">
                    <h2 className="info-card-tittle">Para obtener el link de la sesión deberá:</h2>
                    <ul>
                        <li className="info-card-tittle">
                            Iniciar sesión con su cuenta de Pibank
                        </li>
                        <li className="info-card-tittle">
                            Agregar datos del cliente
                        </li>
                    </ul>
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </div>
            </Grid>
        </Container>
    )
}
