import { useEffect, useState } from 'react';
import { useURlCopy } from '../hooks/copyUrl'
import {
  AudioInputControl,
  AudioOutputControl,
  ControlBar,
  ControlBarButton,
  Phone,
  useMeetingManager,
  MeetingStatus,
  useMeetingStatus,
  VideoInputControl,
  VideoTileGrid,
  Pin,

} from 'amazon-chime-sdk-component-library-react';
import { DeleteMediaPipelineCommand } from '@aws-sdk/client-chime-sdk-media-pipelines';
import { endMeeting } from '../utils/api';
import { getClientMediaPipe } from '../params_aws/params_create_client_chime_pipe';
import { useLocation } from 'react-router-dom';
import { globalMediaPipeObject } from '../utils/global-objects';
import '../styles/globals.css'
import { ControlBarStyle } from '../styles/styles';
import { useMsal } from '@azure/msal-react';

const Meeting = () => {
  const [validationUrlName, setValidationUrlName] = useState('');
  const [validationUrlId, setValidationUrlId] = useState('');
  const [attendeeNameAgent, setNameAgent] = useState('');
  const [showURL, setShowURL] = useState(false);
  const { hash } = useLocation();


  function SplitUrl() {
    let arr = hash?.split('/') || '';
    let id = arr[2]?.split('=') || '';
    let nameClient = arr[3]?.split('=') || '';
    let nameAgent = arr[4]?.split('=') || '';
    let id2 = id[1]?.toString() || '';
    let nameClient2 = nameClient[1]?.toString() || '';
    let nameAgent2 = nameAgent[1]?.toString() || '';
    setValidationUrlId(id2)
    setValidationUrlName(nameClient2);
    setNameAgent(nameAgent2);
  }


  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();

  const clickedEndMeeting = async () => {
    const meetingId = meetingManager.meetingId;
    if (meetingId) {
      await endMeeting(meetingId);
      await meetingManager.leave();
      await deleteMeeting();
    }
    if (showURL) {
      window.location.replace('');
    } else {
      window.location.replace('https://www.pibank.co/');
    }
  }

  async function deleteMeeting() {
    try {
      const d = await deleteMediaPipeline(globalMediaPipeObject.MediaPipelineId);
      localStorage.setItem('HHFM deleteMedia', JSON.stringify(d))
      const dd = await deleteMediaPipeline(localStorage.getItem('MediaPipelineId')!);
      localStorage.setItem('HHFM deleteMedia local', JSON.stringify(dd))
    } catch (error: any) {
      if (error.$metadata.attempts != 3) deleteMeeting();
      localStorage.setItem('HHFM error', JSON.stringify(error))
      console.log(`${process.env.REACT_APP_LOG_ERROR}`, error)
    }
  }

  async function deleteMediaPipeline(MediaPipelineId: string) {
    const client = getClientMediaPipe();

    let paramsDelete = { MediaPipelineId: MediaPipelineId };
    const commandDelete = new DeleteMediaPipelineCommand(paramsDelete)
    try {
      const dataDelete = await client.send(commandDelete);
      console.log(`${process.env.REACT_APP_LOG_DATA_DELETE}`, dataDelete)
    } catch (error) {
      console.log(`${process.env.REACT_APP_LOG_ERROR}`, error)
    }
  }



  useEffect(() => {
    if (hash !== '') {
      SplitUrl()
    }
    if (validationUrlId === '' && validationUrlName === '') {
      setShowURL(true);


    } else {

      setShowURL(false);

      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        clickedEndMeeting();
        ev.returnValue = 'Are you sure you want to close?';
        return ev.returnValue;
      }
      );
    }
  });

  return (

    <div className="control-bar">
      <VideoTileGrid layout="standard" />
      {meetingStatus === MeetingStatus.Succeeded ?
        <ControlBar
          layout="undocked-horizontal"
          showLabels
          style={ControlBarStyle}

        >
          <AudioInputControl muteLabel='desactivar' unmuteLabel='activar' />
          <VideoInputControl />
          <AudioOutputControl label="Hablar" />
          <ControlBarButton icon={<Phone />} onClick={clickedEndMeeting} label="Finalizar" />

          {showURL && <ControlBarButton icon={< Pin />} onClick={useURlCopy} label="Ver Url" />}

        </ControlBar>
        :
        <div />
      }
    </div>
  );
};



export default Meeting;