import { LogLevel } from '@azure/msal-browser';
export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_LOG_AZURE_CONFIG_CLIENT_ID_DEV}`,
    authority: `${process.env.REACT_APP_LOG_AZURE_CONFIG_AUTHORITY_DEV}`,
    redirectUri: `${process.env.REACT_APP_LOG_AZURE_CONFIG_REDIRECT_URI_DEV}`,
  },
  //cache: {
    //cacheLocation: `${process.env.REACT_APP_LOG_AZURE_CONFIG_CACHE_LOCATION}`, 
    //storeAuthStateInCookie: true,
  //},
  system: {
    loggerOptions: {
      loggerCallback: (
        level: number,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const loginRequest = {
  scopes: ['User.Read'],
};
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Herev1.0/me',
};
