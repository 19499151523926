import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";
import { loginRequest } from "../azure-auth-config";
import { SignInButtonStyle } from "../styles/styles.js";

export const SignInButton = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = (loginType:string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
        if (isAuthenticated) {
            window.location.replace('');
        }
    }
    return (
        <div>
            <Button onClick={() => handleLogin("redirect")} style={SignInButtonStyle} variant="contained">Iniciar sesión</Button>
        </div>

    )
}