import { makeStyles } from '@material-ui/core'
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from 'react'
import { ThemeProvider } from 'styled-components';
import MeetingForm from './MeetingForm';
import {
    MeetingProvider,
    lightTheme
} from 'amazon-chime-sdk-component-library-react';
import Meeting from './Meeting';
import { msalConfig } from "../azure-auth-config";
import { Navbar } from './Navbar';

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar
}))

const msalInstance = new PublicClientApplication(msalConfig);

export const SessionMeet = () => {

    return (
        <div>
            <MsalProvider instance={msalInstance}>
                <ThemeProvider theme={lightTheme}>
                    <MeetingProvider>
                        <MeetingForm />
                        <Meeting />
                    </MeetingProvider>
                </ThemeProvider>
                <Navbar></Navbar>
            </MsalProvider>
        </div>
    )
}
